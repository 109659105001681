import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from './components/layout/Navbar'
import Landing from './components/layout/Landing'
import Register from './components/auth/Register'
import Login from './components/auth/Login'
import Dashboard from './components/dashboard/Dashboard'
import EditProfile from './components/profile/EditProfile'
import Profile from './components/profile/Profile'
import AddRide from './components/profile/AddRide'
import Footer from './components/layout/Footer'
import Imprint from './components/imprint/Imprint'
import Profiles from './components/profiles/Profiles'
import Search from './components/search/Search'
import Basket from './components/tickets/Basket'
import Confirmation from './components/tickets/Confirmation'
import Rides from './components/rides/Rides'
import Prices from './components/prices/Prices'
import PrivateRoute from './components/routing/PrivateRoute'
import { Provider } from 'react-redux'
import store from './store'
import setAuthToken from './utils/setAuthToken'
import { loadUser } from './actions/auth'
import { loadTicketPrice } from './actions/tickets'
// CSS
import './App.css';

if (localStorage.token) {
  setAuthToken(localStorage.token)
}

const App = () => {

  useEffect(() => {
    store.dispatch(loadUser())
    store.dispatch(loadTicketPrice())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <header>
            <Navbar />
          </header>
          <main>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/profiles" component={Profiles} />
              <Route exact path="/profiles/:id" component={Profile} />
              <Route exact path="/basket" component={Basket} />
              <Route exact path="/confirmation" component={Confirmation} />
              <Route exact path="/search" component={Search} />
              <Route exact path="/rides" component={Rides} />
              <Route exact path="/prices" component={Prices} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/imprint" component={Imprint} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/edit-profile" component={EditProfile} />
              <PrivateRoute exact path="/add-ride" component={AddRide} />
            </Switch>
          </main>
          <footer>
            <Footer />
          </footer>
        </Fragment>
      </Router>
    </Provider>
  )
}

export default App;
