import {
    TICKET_PRICE_LOADED,
    TICKET_PRICE_ERROR,
    ADD_LOTTERY_TICKET,
    DELETE_LOTTERY_TICKET,
    CREATE_LOTTERY_TICKETS,
    LOTTERY_TICKETS_ERROR
} from "../actions/types"

const initialState = {
    ticketPrice: 0,
    rideTickets: {},
    lotteryTickets: [],
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case TICKET_PRICE_LOADED:
            return {
                ...state,
                ticketPrice: parseFloat(payload)
            }
        case TICKET_PRICE_ERROR:
            return {
                ...state,
                ticketPrice: 0
            }
        case ADD_LOTTERY_TICKET:
            const ride = payload
            const rideTickets = { ...state.rideTickets }

            if (ride in rideTickets) {
                rideTickets[ride] = rideTickets[ride] + 1
            } else {
                rideTickets[ride] = 1
            }

            return {
                ...state,
                rideTickets: rideTickets
            }
        case DELETE_LOTTERY_TICKET:
            const ride2 = payload
            const rideTickets2 = { ...state.rideTickets }

            if (ride2 in rideTickets2) {
                rideTickets2[ride2] = rideTickets2[ride2] - 1

                if (rideTickets2[ride2] <= 0) {
                    delete (rideTickets2[ride2])
                }
            }

            return {
                ...state,
                rideTickets: rideTickets2
            }
        case CREATE_LOTTERY_TICKETS:
            return {
                ...state,
                rideTickets: {},
                lotteryTickets: payload,
                error: {}
            }
        case LOTTERY_TICKETS_ERROR:
            return {
                ...state,
                lotteryTickets: [],
                error: payload
            }
        default:
            return state
    }
}
