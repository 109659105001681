import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { logout } from '../../actions/auth'
import { ReactComponent as ReactLogo } from '../../img/viehmarkt.svg'

import M from 'materialize-css'

const Navbar = ({ auth: { isAuthenticated, loading, user }, rideTickets, logout }) => {

    let ticketCount = 0

    for (const ride in rideTickets) {
        ticketCount += rideTickets[ride]
    }

    const authLinks = (
        <Fragment>
            <li><Link to="/profiles"><i className="fas fa-user-friends"></i>{' '}Schausteller</Link></li>
            <li><Link to="/search"><i className="fas fa-search"></i>{' '}Suche</Link></li>
            <li><Link to="/dashboard"><i className="fas fa-user"></i>{' '}{user !== null ? user.name : 'Profil'}</Link></li>
            <li><Link onClick={logout} to="#!"><i className="fas fa-sign-out-alt"></i>{' '}Logout</Link></li>
        </Fragment>
    )

    const guestLinks = (
        <Fragment>
            <li><Link to="/basket"><i className="fas fa-ticket-alt"></i>{' '}Meine Lose: {ticketCount}</Link></li>
            <li><Link to="/rides"><i className="fas fa-dice-d20"></i>{' '}Lose ziehen</Link></li>
            <li><Link to="/prices"><i className="fas fa-gifts"></i>{' '}Preise</Link></li>
            <li><Link to="/profiles"><i className="fas fa-user-friends"></i>{' '}Schausteller</Link></li>
            <li><Link to="/search"><i className="fas fa-search"></i>{' '}Suche</Link></li>
            <li><Link to="/register"><i className="fas fa-user-plus"></i>{' '}Registrierung</Link></li>
            <li><Link to="/login"><i className="fas fa-sign-in-alt"></i>{' '}Login</Link></li>
        </Fragment>
    )

    document.addEventListener('DOMContentLoaded', function () {
        var elems = document.querySelectorAll('.sidenav')
        M.Sidenav.init(elems)
    })

    return (
        <Fragment>
            <div class="navbar-fixed">
                <nav>
                    <div className="nav-wrapper light-blue lighten-1">
                        <ReactLogo height={"2rem"} />
                        <Link to="/" className="comic">Viehmarkt.online</Link>
                        <Link to="#" data-target="mobile-demo" className="sidenav-trigger"><i class="material-icons">menu</i></Link>
                        <ul className="right hide-on-med-and-down">
                            {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
                        </ul>
                    </div>
                </nav>
            </div>

            <ul className="sidenav" id="mobile-demo">
                {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
            </ul>
        </Fragment>
    )
}

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    rideTickets: state.tickets.rideTickets
})

export default connect(mapStateToProps, { logout })(Navbar)
