import {
    SEARCHING,
    SEARCH_RESULT,
    CLEAR_SEARCH,
    SEARCH_ERROR
} from '../actions/types'

const initialState = {
    query: '',
    profiles: [],
    loading: false,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SEARCHING:
            return {
                ...state,
                loading: true
            }
        case SEARCH_RESULT:
            return {
                ...state,
                query: payload.query,
                profiles: payload.profiles,
                loading: false
            }
        case CLEAR_SEARCH:
            return {
                ...state,
                query: '',
                profiles: [],
                loading: false,
                error: {}
            }
        case SEARCH_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
                query: null,
                profiles: []
            }
        default:
            return state
    }
}
