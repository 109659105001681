import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import { register } from '../../actions/auth'
import PropTypes from 'prop-types'
import M from 'materialize-css'
import Alert from '../layout/Alert'

const Register = ({ setAlert, register, isAuthenticated }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [])

    // https://reactjs.org/docs/hooks-state.html
    // https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation

    const [formData, setFormData] = useState(
        {
            name: '',
            status: 'Schausteller',
            email: '',
            password: '',
            password2: ''
        }
    )

    const { name, status, email, password, password2 } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async e => {
        e.preventDefault()
        if (password !== password2) {
            setAlert('Die Passwörter sind unterschiedlich', 'error');
        } else {
            register({ name, status, email, password })
        }
    }

    // Redirect if logged in
    if (isAuthenticated) {
        return <Redirect to='/dashboard' />
    }

    const styleNone = {
        display: 'none'
    }

    return (
        <Fragment>
            <section className="container">
                <Alert />
                <h1 className="header center orange-text">Registrierung</h1>

                <h5><i className="fas fa-user-plus light-blue-text"></i>{' '}Registrieren Sie sich hier als Schausteller oder Händler.</h5>

                <div className="row">

                    <form className="col s12" onSubmit={e => onSubmit(e)}>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="name"
                                    type="text"
                                    className="validate"
                                    name="name"
                                    value={name} onChange={e => onChange(e)} required />
                                <label for="name">Name</label>
                            </div>
                        </div>

                        <div className="row" style={styleNone}>
                            <div className="input-field col s12">
                                <select
                                    id="status"
                                    name="status"
                                    value={status} onChange={e => onChange(e)} required>
                                    <option value="Schausteller">Schausteller</option>
                                </select>
                                <label>Dein Status</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="email"
                                    type="email"
                                    className="validate"
                                    name="email"
                                    value={email} onChange={e => onChange(e)} required />
                                <label for="email">Email</label>
                                <small>Diese Seite verwendet <a href="https://gravatar.com/" target="_blank" rel="noopener noreferrer">Gravatar</a> mit Ihrer Email-Adresse</small>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="password"
                                    type="password"
                                    className="validate"
                                    name="password"
                                    value={password} onChange={e => onChange(e)}
                                    minLength="6" />
                                <label for="password">Passwort</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="password2"
                                    type="password"
                                    className="validate"
                                    name="password2"
                                    value={password2} onChange={e => onChange(e)}
                                    minLength="6" />
                                <label for="password2">Passwort bestätigen</label>
                            </div>
                        </div>

                        <input type="submit" className="btn btn-margin waves-effect waves-light orange" value="Registrieren" />
                    </form>

                </div>

                <p>
                    Sie sind schon registriert? <Link to="/login">Anmeldung</Link>
                </p>
            </section>
        </Fragment>
    )
}

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { setAlert, register })(Register)
