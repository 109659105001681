import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SearchForm from './SearchForm'
import ProfileItem from '../profiles/ProfileItem'
import Spinner from '../layout/Spinner'

const Search = ({ search: { profiles, loading } }) => {

    return (
        <Fragment>
            <section className="container">
                <SearchForm />
                <div>
                    {loading ?
                        <Spinner /> :
                        <Fragment>
                            {
                                profiles.length > 0 ? (
                                    <Fragment>
                                        <ul className="collection">
                                            {
                                                profiles.map(profile => (
                                                    <ProfileItem key={profile._id} profile={profile} />
                                                ))
                                            }
                                        </ul>
                                    </Fragment>
                                ) :
                                    ''
                            }
                        </Fragment>
                    }
                </div>
            </section>
        </Fragment >
    )
}

Search.propTypes = {
    search: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    search: state.search
})

export default connect(mapStateToProps, {})(Search)
