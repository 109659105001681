import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const DashboardActions = ({ profile: { profile, loading } }) => {
    if (profile) {
        return (
            <div className="dash-buttons">
                <Link to="/edit-profile" className="btn btn-margin waves-effect waves-light orange">
                    <i className="fas fa-mail-bulk"></i> Unternehmensprofil
                </Link>
                <Link to="/add-ride" className="btn btn-margin waves-effect waves-light orange">
                    <i className="fas fa-dice-d20"></i> Auftritte
                </Link>
            </div>
        )
    } else {
        return (
            <Fragment>
                <div className="dash-buttons">
                    <Link to="/edit-profile" className="btn btn-margin waves-effect waves-light orange">
                        <i className="fas fa-mail-bulk"></i> Unternehmensprofil
                    </Link>
                </div>
                <p>Sie haben noch kein Unternehmensprofil angelegt. Sie benötigen ein Unternehmensprofil, um ein Fahrgeschäft oder einen Stand anzulegen.</p>
            </Fragment>
        )
    }
}

DashboardActions.propTypes = {
    profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    profile: state.profile
})

export default connect(mapStateToProps, {})(DashboardActions)
