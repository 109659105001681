import axios from 'axios'
import {
    TICKET_PRICE_LOADED,
    TICKET_PRICE_ERROR,
    ADD_LOTTERY_TICKET,
    DELETE_LOTTERY_TICKET,
    CREATE_LOTTERY_TICKETS,
    LOTTERY_TICKETS_ERROR
} from "./types"

export const loadTicketPrice = () => async dispatch => {

    try {
        const res = await axios.get('/api/tickets/price')

        dispatch({
            type: TICKET_PRICE_LOADED,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: TICKET_PRICE_ERROR
        })
    }
}

// Add lottery ticket for ride ID
export const addLotteryTicket = rideId => async dispatch => {
    dispatch({
        type: ADD_LOTTERY_TICKET,
        payload: rideId
    })
}

// Delete lottery ticket for ride ID
export const deleteLotteryTicket = rideId => async dispatch => {
    dispatch({
        type: DELETE_LOTTERY_TICKET,
        payload: rideId
    })
}

// Create lottery tickets for order ID
export const createLotteryTickets = (orderID, rideTickets) => async dispatch => {

    try {
        const res = await axios.post('/api/tickets', { orderID, rideTickets })

        dispatch({
            type: CREATE_LOTTERY_TICKETS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: LOTTERY_TICKETS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}
