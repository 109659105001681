import React, { Fragment, useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Alert from '../layout/Alert'
import M from 'materialize-css'
import { upsertProfile, getCurrentProfile } from '../../actions/profile'

const EditProfile = ({ upsertProfile, profile: { profile, loading }, history }) => {

    useEffect(() => {
        if (!profile) {
            getCurrentProfile()
        }

        if (!loading && profile) {
            const profileData = { ...formData }

            for (const key in profile) {
                if (key in profileData) {
                    profileData[key] = profile[key]
                }
            }

            for (const key in profile.social) {
                if (key in profileData) {
                    profileData[key] = profile.social[key];
                }
            }

            if (Array.isArray(profileData.skills))
                profileData.skills = profileData.skills.join(', ');

            setFormData(profileData);
        }

        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);

        elems = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elems, { format: 'yyyy' });

        M.updateTextFields()
    }, [loading, profile])

    const [formData, setFormData] = useState({
        company: '',
        location: '',
        founded: '',
        firstappearance: '',
        website: '',
        description: '',
        statement: '',
        facebook: '',
        twitter: '',
        instagram: '',
        youtube: ''
    })

    const [displaySocialNetworks, toggleSocialNetworks] = useState(false)

    const {
        company,
        location,
        founded,
        firstappearance,
        website,
        description,
        statement,
        facebook,
        twitter,
        instagram,
        youtube
    } = formData

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onSubmit = e => {
        e.preventDefault()

        upsertProfile(formData, history)
    }

    return (
        <Fragment>
            <section className="container">
                <Alert />
                <h1 className="header orange-text">Ihr Profil</h1>
                <p className="lead">
                    <i className="fas fa-user light-blue-text"></i>{' '}Informationen über Ihr Unternehmen/Ihren Gewerbebetrieb.
                </p>
                <div className="row">

                    <form className="col s12" onSubmit={e => onSubmit(e)}>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="company"
                                    type="text"
                                    placeholder="Unternehmen"
                                    name="company"
                                    value={company}
                                    onChange={e => onChange(e)} />
                                <label className="active" for="company">Der Name Ihres Unternehmens/Gewerbebetriebs inkl. Rechtsform</label>
                                <span class="helper-text orange-text">Diese Angabe ist erforderlich.</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="location"
                                    type="text"
                                    placeholder="Ort"
                                    name="location"
                                    value={location}
                                    onChange={e => onChange(e)} />
                                <label className="active" for="company">Der Sitz Ihres Unternehmens/Gewerbebetriebs</label>
                                <span class="helper-text orange-text">Diese Angabe ist erforderlich.</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="founded"
                                    type="text"
                                    placeholder="yyyy"
                                    name="founded"
                                    value={founded}
                                    onChange={e => onChange(e)} />
                                <label className="active" for="company">Das Gründungsjahr Ihres Unternehmens/Gewerbebetriebs</label>
                                <span class="helper-text orange-text">Diese Angabe ist erforderlich.</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="firstappearance"
                                    type="text"
                                    placeholder="yyyy"
                                    name="firstappearance"
                                    value={firstappearance}
                                    onChange={e => onChange(e)} />
                                <label className="active" for="company">Ihr erster Auftritt auf dem Viehmarkt</label>
                                <span class="helper-text orange-text">Diese Angabe ist erforderlich.</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="website" type="text"
                                    placeholder="Webseite" name="website"
                                    value={website}
                                    onChange={e => onChange(e)} />
                                <label className="active" for="website">Ihre Webseite</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <textarea
                                    id="description"
                                    placeholder="Erzählen Sie ein wenig über Ihr Unternehmen/Ihren Gewerbebetrieb..."
                                    name="description" value={description}
                                    onChange={e => onChange(e)}>
                                </textarea>
                                <label className="active" for="description">Beschreibung</label>
                                <span class="helper-text orange-text">Diese Angabe ist erforderlich.</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <textarea
                                    id="statement"
                                    placeholder="Was Ihr Unternehmen/Ihren Gewerbebetrieb mit dem Viehmarkt verbindet..."
                                    name="statement" value={statement}
                                    onChange={e => onChange(e)}>
                                </textarea>
                                <label className="active" for="statement">Ihr Bezug zum Viehmarkt</label>
                                <span class="helper-text orange-text">Diese Angabe ist erforderlich.</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <button onClick={() => toggleSocialNetworks(!displaySocialNetworks)} type="button" className="btn btn-margin waves-effect waves-light orange">
                                    Ihre sozialen Netzwerke...
                                </button>
                            </div>
                        </div>

                        {displaySocialNetworks && <Fragment>

                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="fab fa-facebook fa-2x prefix"></i>
                                    <input
                                        type="text"
                                        placeholder="Facebook URL"
                                        name="facebook"
                                        value={facebook}
                                        onChange={e => onChange(e)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="fab fa-twitter fa-2x prefix"></i>
                                    <input
                                        type="text"
                                        placeholder="Twitter URL"
                                        name="twitter"
                                        value={twitter}
                                        onChange={e => onChange(e)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="fab fa-instagram fa-2x prefix"></i>
                                    <input
                                        type="text"
                                        placeholder="Instagram URL"
                                        name="instagram"
                                        value={instagram}
                                        onChange={e => onChange(e)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="fab fa-youtube fa-2x prefix"></i>
                                    <input
                                        type="text"
                                        placeholder="YouTube URL"
                                        name="youtube"
                                        value={youtube}
                                        onChange={e => onChange(e)} />
                                </div>
                            </div>

                        </Fragment>}

                        <input type="submit" value="Speichern" className="btn btn-margin waves-effect waves-light orange" />

                        <Link to="/dashboard" className="btn btn-margin waves-effect waves-light orange lighten-2" >Zurück</Link>
                    </form>
                </div>
            </section>
        </Fragment >
    )
}

EditProfile.propTypes = {
    upsertProfile: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    profile: state.profile
})

// 'withRouter()' required to be able to use 'history' object
export default connect(mapStateToProps, { upsertProfile, getCurrentProfile })(withRouter(EditProfile))
