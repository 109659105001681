import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const ProfileDesc = ({ description }) => {
    return (
        <Fragment>
            {
                description ?
                    <div className="col s12 m7">
                        <div className="card white">
                            <div className="card-content">
                                <p>{description}</p>
                            </div>
                        </div>
                    </div>

                    :
                    ''
            }
        </Fragment>
    )
}

ProfileDesc.propTypes = {
    description: PropTypes.object.isRequired
}

export default ProfileDesc
