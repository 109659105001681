import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import M from 'materialize-css'
import { queryProfiles } from '../../actions/search'

const SearchForm = ({ search: { query: oldQuery }, queryProfiles }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields()
    })

    const queryChips = []

    if (oldQuery) {
        oldQuery.split(' ').forEach(s => {
            queryChips.push(s)
        });
    }

    const [newQuery, setQuery] = useState('')

    const onChange = e => { setQuery(e.target.value) }

    const onSubmit = e => {
        e.preventDefault()

        queryProfiles(newQuery)
    }

    return (
        <Fragment>
            <div class="row searchform">
                <form class="col s12" onSubmit={e => onSubmit(e)}>
                    <div class="row">
                        <div class="input-field col s7">
                            <i class="material-icons prefix">search</i>
                            <input id="query" type="text" class="validate" onChange={e => onChange(e)} />
                            <label for="query">Suche nach einem Schausteller</label>
                        </div>
                    </div>
                </form>
            </div>
            {queryChips.length > 0 ?
                (
                    <div className="col s12 m7">
                        <div className="card white">
                            <div className="card-content">
                                {queryChips.map(s => (
                                    <div className="chip">{s}</div>
                                ))
                                }
                            </div>
                        </div>
                    </div>) :
                (
                    ''
                )
            }
        </Fragment>
    )
}

SearchForm.propTypes = {
    queryProfiles: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    search: state.search
})

export default connect(mapStateToProps, { queryProfiles })(SearchForm)
