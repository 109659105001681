import React, { Fragment } from 'react'

const Imprint = () => {

    return (
        <Fragment>
            <section className="container">
                <h1 className="header orange-text">Impressum</h1>
                <p className="lead">
                    <i className="fas fa-stamp light-blue-text"></i>{' '}Angaben gemäß § 5 TMG:
                </p>
                <p>Alexander Paar</p>
                <p>
                    <h6>Postanschrift:</h6>
                    Jägerwinkel 57<br />
                    24214 Gettorf
                </p>
                <p>
                    <h6>Kontakt:</h6>
                    Telefon: 0171-2656413<br />
                    E-Mail: alexpaar@zhimantic.com
                </p>
            </section>
        </Fragment>
    )
}

export default Imprint
