
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILES = 'GET_PROFILES'
export const UPSERT_PROFILE = 'UPSERT_PROFILE'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const GET_GITHUB_REPOS = 'GET_GITHUB_REPOS'
export const SEARCHING = 'SEARCHING'
export const SEARCH_RESULT = 'SEARCH_RESULT'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const SEARCH_ERROR = 'SEARCH_ERROR'
export const GET_RIDES = 'GET_RIDES'
export const RIDES_ERROR = 'RIDES_ERROR'
export const TICKET_PRICE_LOADED = 'TICKET_PRICE_LOADED'
export const TICKET_PRICE_ERROR = 'TICKET_PRICE_ERROR'
export const ADD_LOTTERY_TICKET = 'ADD_LOTTERY_TICKET'
export const DELETE_LOTTERY_TICKET = 'DELETE_LOTTERY_TICKET'
export const CREATE_LOTTERY_TICKETS = 'CREATE_LOTTERY_TICKETS'
export const LOTTERY_TICKETS_ERROR = 'LOTTERY_TICKETS_ERROR'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
