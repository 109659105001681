import React, { Fragment, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Alert from '../layout/Alert'
import { login } from '../../actions/auth'

const Login = ({ login, isAuthenticated }) => {

    // https://reactjs.org/docs/hooks-state.html
    // https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation

    const [formData, setFormData] = useState(
        {
            email: '',
            password: ''
        }
    )

    const { email, password } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async e => {
        e.preventDefault()
        login(email, password)
    }

    // Redirect if logged in
    if (isAuthenticated) {
        return <Redirect to='/dashboard' />
    }

    return (
        <Fragment>
            <section className="container">
                <Alert />
                <h1 className="header center orange-text">Login</h1>

                <h5><i className="fas fa-sign-in-alt light-blue-text"></i>{' '}Anmeldung für registrierte Schausteller und Händler</h5>

                <div className="row">

                    <form className="col s12" onSubmit={e => onSubmit(e)}>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="email"
                                    type="email"
                                    className="validate"
                                    name="email"
                                    value={email} onChange={e => onChange(e)} required />
                                <label for="email">Email</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="password"
                                    type="password"
                                    className="validate"
                                    name="password"
                                    value={password} onChange={e => onChange(e)}
                                    minLength="6" />
                                <label for="password">Passwort</label>
                            </div>
                        </div>

                        <input type="submit" className="btn btn-margin waves-effect waves-light orange" value="Login" />
                    </form>

                </div>

                <p>
                    Sie haben noch keinen Account? <Link to="/register">Registrierung</Link>
                </p>
            </section>
        </Fragment>
    )
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { login })(Login)
