import React, { Fragment, useEffect } from 'react'
import M from 'materialize-css'
import firstPrice from '../../img/1stPrice.png'
import secondPrice from '../../img/2ndPrice.png'
import thirdPrice from '../../img/3rdPrice.jpg'
import fourthPrice from '../../img/4thPrice.png'
import fifthPrice from '../../img/5thPrice.png'

const Prices = () => {

    useEffect(() => {
        var elems = document.querySelectorAll('.carousel');
        M.Carousel.init(elems);
    }, [])

    return (
        <Fragment>
            <section className="container">
                <h1 className="header orange-text">Preise</h1>
                <p className="lead">
                    <i className="fas fa-gifts light-blue-text"></i>{' '}Auch in diesem Jahr gibt es bei der Viehmarktslotterie wieder viele hochwertige Preise zu gewinnen.
                </p>
                <div className="carousel">
                    <a className="carousel-item" href="#one!"><img src={firstPrice} /></a>
                    <a className="carousel-item" href="#two!"><img src={secondPrice} /></a>
                    <a className="carousel-item" href="#three!"><img src={thirdPrice} /></a>
                    <a className="carousel-item" href="#three!"><img src={fourthPrice} /></a>
                    <a className="carousel-item" href="#three!"><img src={fifthPrice} /></a>
                </div>
                <ul class="collection with-header">
                    <li class="collection-header"><h4>Die Hauptpreise</h4></li>
                    <li class="collection-item"><div>Ein 26 Zoll Fernseher<a href="#!" class="secondary-content"><i class="material-icons light-blue-text">looks_one</i></a></div></li>
                    <li class="collection-item"><div>Ein 26 Zoll Fahrrad<a href="#!" class="secondary-content"><i class="material-icons light-blue-text">looks_two</i></a></div></li>
                    <li class="collection-item"><div>Ein Kaffee-Vollautomat<a href="#!" class="secondary-content"><i class="material-icons light-blue-text">looks_3</i></a></div></li>
                    <li class="collection-item"><div>Eine Schlagbohrmaschine<a href="#!" class="secondary-content"><i class="material-icons light-blue-text">looks_4</i></a></div></li>
                    <li class="collection-item"><div>Ein 26 Zoll Sägebock<a href="#!" class="secondary-content"><i class="material-icons light-blue-text">looks_5</i></a></div></li>
                </ul>
                <ul class="collection with-header">
                    <li class="collection-header"><h4>Die weiteren Preise</h4></li>
                    <li class="collection-item">Leselampe</li>
                    <li class="collection-item">Tortenstückeinteiler</li>
                    <li class="collection-item">Personenwaage</li>
                    <li class="collection-item">Toaster</li>
                    <li class="collection-item">Schneidbrett</li>
                    <li class="collection-item">Turnbeutel</li>
                    <li class="collection-item">Kugelschreiber</li>
                    <li class="collection-item">Microfaser-Handtuch</li>
                    <li class="collection-item">6 Weingläser</li>
                    <li class="collection-item">Picknickdecke</li>
                    <li class="collection-item">Rucksack</li>
                    <li class="collection-item">Käsereibe</li>
                    <li class="collection-item">Spardose</li>
                    <li class="collection-item">Messbecher</li>
                    <li class="collection-item">Kompaktatlas Deutschland</li>
                </ul>
            </section>
        </Fragment>
    )
}

export default Prices
