import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from '../layout/Spinner'
import { addLotteryTicket } from '../../actions/tickets'

const AWS = require('aws-sdk/dist/aws-sdk-react-native');

const Ride = ({ ride, addLotteryTicket }) => {

    useEffect(() => {
        const s3 = new AWS.S3({
            accessKeyId: 'AKIAQVO5MXRH7SXUYHGO',
            secretAccessKey: '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',
            region: 'eu-west-1'
        })

        s3.getSignedUrlPromise('getObject', {
            Bucket: 'viehmarkt',
            Key: ride._id + '.' + ride.photo.substring(ride.photo.lastIndexOf('/') + 1),
            Expires: 60 * 5
        }).then(url => setUrl(url))
    }, [])

    const [url, setUrl] = useState(null)

    const onClick = e => { addLotteryTicket(ride._id) }

    return (
        <Fragment>
            {
                ride ?
                    (
                        url ?
                            (
                                <div class="row">
                                    <div class="col s12 m12">
                                        <div class="card">
                                            <div class="card-image">
                                                <img src={url} className="responsive-img" />
                                                <span class="card-title ride-title">{ride.title}</span>
                                                <a
                                                    class="btn-floating btn-large halfway-fab waves-effect waves-light red"
                                                    onClick={onClick}>
                                                    <i class="material-icons">add</i>
                                                </a>
                                            </div>
                                            <div class="card-content">
                                                <p>{ride.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <Spinner />
                            )
                    )
                    :
                    (
                        ''
                    )
            }
        </Fragment>
    )
}

Ride.propTypes = {
    addLotteryTicket: PropTypes.func.isRequired
}

export default connect(null, { addLotteryTicket })(Ride)
