import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { PayPalButton } from 'react-paypal-button-v2'
import { getRides } from '../../actions/rides'
import { createLotteryTickets, addLotteryTicket, deleteLotteryTicket } from '../../actions/tickets'

const Basket = ({ rides, rideTickets, ticketPrice, getRides, createLotteryTickets, addLotteryTicket, deleteLotteryTicket, history }) => {

    useEffect(() => {
        getRides()
    }, [getRides])

    const getRideName = id => {
        for (const index in rides) {
            if (rides[index]._id === id) {
                return rides[index].title
            }
        }
        return id
    }

    const formatTicketPrice = num => num.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    let numberOfTickets = 0

    for (const ride in rideTickets) {
        numberOfTickets += rideTickets[ride]
    }

    const amount = numberOfTickets * ticketPrice

    return (
        <Fragment>
            <section className="container">
                <h1 className="header orange-text">Ihre Lose</h1>
                <p className="lead">
                    {rideTickets && Object.keys(rideTickets).length > 0 ? (
                        <Fragment>
                            <i className="fas fa-ticket-alt light-blue-text"></i>{' '}Hier sehen Sie Ihre gezogenen Viehmarktslose. Ihre Losnummern werden Ihnen angezeigt, wenn Sie Ihre Lose mit einer der aufgeführten Zahlungsmöglichkeiten kaufen. {numberOfTickets === 1 ? 'Ihr Viehmarktslos kostet' : 'Ihre ' + numberOfTickets + ' Viehmarktslose kosten'} {formatTicketPrice(amount)}€.
                        </Fragment>
                    ) :
                        (
                            <Fragment>
                                <i className="fas fa-ticket-alt light-blue-text"></i>{' '}Sie haben noch keine Viehmarktslose gezogen. Ziehen Sie Ihre Lose <Link to="/rides">hier</Link>.
                            </Fragment>
                        )
                    }
                </p>
                {rideTickets && Object.keys(rideTickets).length > 0 ? (
                    <ul class="collection">
                        {Object.keys(rideTickets).map(ride => (
                            <li class="collection-item" key={ride}>
                                <div>
                                    <span className="large orange-text">{rideTickets[ride]}</span><span>{' (' + getRideName(ride) + ')'}</span>
                                    <span class="secondary-content"><Link to="#!" onClick={e => deleteLotteryTicket(ride)}><span className="light-blue-text large">-</span></Link>{' '}<Link to="#!" onClick={e => addLotteryTicket(ride)}><span className="light-blue-text large">+</span></Link></span>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) :
                    (
                        ''
                    )
                }

                {rideTickets && Object.keys(rideTickets).length > 0 ? (
                    <p>
                        <PayPalButton
                            amount={amount}
                            currency="EUR"
                            shippingPreference="NO_SHIPPING"
                            onSuccess={async (details, data) => {
                                createLotteryTickets(data.orderID, rideTickets)

                                history.push('/confirmation')
                            }}
                            options={{
                                clientId: "AWjrgCt8lzHQyHq3sUIppaMV03vq_sRDZhajwRndjFHIHDGyJ4du8pubaRWN3PW2CMxk99-WH6ryMHW3",
                                currency: "EUR",
                                shippingPreference: "NO_SHIPPING"
                            }}
                        />
                    </p>
                ) :
                    (
                        ''
                    )
                }
            </section>
        </Fragment>
    )
}

Basket.propTypes = {
    props: PropTypes.object.isRequired,
    rides: PropTypes.array.isRequired,
    rideTickets: PropTypes.object.isRequired,
    ticketPrice: PropTypes.object.isRequired,
    getRides: PropTypes.func.isRequired,
    createLotteryTickets: PropTypes.func.isRequired,
    addLotteryTicket: PropTypes.func.isRequired,
    deleteLotteryTicket: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    rides: state.rides.rides,
    rideTickets: state.tickets.rideTickets,
    ticketPrice: state.tickets.ticketPrice
})

export default connect(mapStateToProps, { getRides, createLotteryTickets, addLotteryTicket, deleteLotteryTicket })(withRouter(Basket))
