import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../layout/Spinner'
import ProfileItem from './ProfileItem'
import { getProfiles } from '../../actions/profile'
import Alert from '../layout/Alert'

const Profiles = ({ getProfiles, profile: { profiles, loading } }) => {

    useEffect(() => {
        getProfiles()
    }, [getProfiles])

    return <Fragment>
        <section className="container">
            <Alert />
            {loading ?
                <Spinner /> :
                <Fragment>
                    <h1 className="header orange-text">Schausteller</h1>
                    <p className="lead">
                        <i className="fas fa-users light-blue-text"></i>{' '}Diese Schausteller treten auf dem Viehmarkt auf.
                    </p>
                    <ul className="collection">
                        {profiles.length > 0 ? (
                            profiles.map(profile => (
                                <ProfileItem key={profile._id} profile={profile} />
                            ))
                        ) :
                            (<h4>Keine Profile gefunden</h4>)
                        }
                    </ul>

                </Fragment>
            }
        </section>
    </Fragment >
}

Profiles.propTypes = {
    getProfiles: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    profile: state.profile
})

export default connect(mapStateToProps, { getProfiles })(Profiles)
