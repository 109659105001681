import React, { Fragment, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../layout/Spinner'
import { getProfileById } from '../../actions/profile'
import ProfileCard from './ProfileCard'
import ProfileDesc from './ProfileDesc'
// import ProfileRides from './ProfileRides'
import ProfileRides2 from './ProfileRides2'
import M from 'materialize-css'

const Profile = ({
    getProfileById,
    profile: { profile, loading },
    auth,
    match }) => {

    useEffect(() => {
        getProfileById(match.params.id)

        var elems = document.querySelectorAll('.collapsible')
        M.Collapsible.init(elems)
    }, [getProfileById, match.params.id])

    let history = useHistory();

    return (
        <Fragment>
            <section className="container">
                {profile === null || loading ?
                    <Spinner /> :
                    <Fragment>
                        <div
                            to="/profiles"
                            className="btn btn-margin waves-effect waves-light orange lighten-2"
                            onClick={() => history.goBack()} >Zurück</div>
                        {
                            auth.isAuthenticated &&
                            auth.loading === false &&
                            auth.user._id === profile.user._id &&
                            (<Link to="/edit-profile" className="btn btn-margin waves-effect waves-light orange">Bearbeiten</Link>)
                        }
                        <ProfileCard profile={profile} />
                        <ProfileDesc description={profile.description} />
                        {/* {
                            profile.rides && profile.rides.length > 0 ? (
                                <ProfileRides rides={profile.rides} />
                            ) :
                                ''
                        } */}
                        {
                            profile.rides && profile.rides.length > 0 ? (
                                <Fragment>
                                    <h4 className="header orange-text"><i className="fas fa-dice-d20"></i>{' '}Auftritte</h4>
                                    <ul className="collapsible">
                                        {profile.rides.map(ride => (
                                            <ProfileRides2 key={ride._id} ride={ride} />
                                        ))}
                                    </ul>
                                </Fragment>
                            ) :
                                ''
                        }
                    </Fragment>}
            </section>
        </Fragment >
    )
}

Profile.propTypes = {
    getProfileById: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    profile: state.profile,
    auth: state.auth
})

export default connect(mapStateToProps, { getProfileById })(Profile)
