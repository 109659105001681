import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
    EmailShareButton, EmailIcon,
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    WhatsappShareButton, WhatsappIcon
} from 'react-share';

const Confirmation = ({ lotteryTickets, error }) => {

    const TITLE = 'Viehmarktslotterie'

    const URL = 'https://www.viehmarkt.online'

    const QUOTE = 'Ich habe mir gerade mein Viehmarktslos gekauft.'

    const SIZE = 64

    return (
        <Fragment>
            <section className="container">
                <h1 className="header orange-text">Ihre Lose</h1>
                <p className="lead">
                    {!error.msg && lotteryTickets && lotteryTickets.length > 0 ?
                        (
                            <Fragment>
                                <i className="fas fa-ticket-alt light-blue-text"></i>{' '}Mit diesen gekauften Viehmarktslosen nehmen Sie an der Verlosung teil.
                            </Fragment>
                        ) :
                        (
                            <Fragment>
                                <i className="fas fa-ticket-alt light-blue-text"></i>{' '}Sie haben keine Viehmarktslose gekauft. Ziehen Sie Ihre Lose <Link to="/rides">hier</Link>.
                            </Fragment>
                        )
                    }
                </p>

                {error.msg ?
                    (
                        <h5 className="red-text">{error.msg}</h5>
                    ) :
                    (
                        ''
                    )
                }

                {lotteryTickets && lotteryTickets.length > 0 ?
                    (
                        <Fragment>
                            {lotteryTickets.map(ticket => (
                                <h2 className="header light-blue-text lottery-ticket">{ticket.ticketNumber}</h2>
                            ))
                            }

                            <h2 className="header orange-text">Viel Glück{lotteryTickets[0].given_name ? `, ${lotteryTickets[0].given_name}` : ''}!</h2>
                        </Fragment>
                    ) :
                    (
                        ''
                    )
                }

                <EmailShareButton url={URL} subject={TITLE} body={QUOTE}>
                    <EmailIcon size={SIZE} round />
                </EmailShareButton>

                <FacebookShareButton url={URL} quote={QUOTE}>
                    <FacebookIcon size={SIZE} round />
                </FacebookShareButton>

                <TwitterShareButton url={URL} title={TITLE}>
                    <TwitterIcon size={SIZE} round />
                </TwitterShareButton>

                <WhatsappShareButton url={URL} title={TITLE}>
                    <WhatsappIcon size={SIZE} round />
                </WhatsappShareButton>

            </section>
        </Fragment>
    )
}

Confirmation.propTypes = {
    lotteryTickets: PropTypes.array.isRequired,
    error: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    lotteryTickets: state.tickets.lotteryTickets,
    error: state.tickets.error
})

export default connect(mapStateToProps, {})(Confirmation)
