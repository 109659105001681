import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const Landing = ({ isAuthenticated }) => {

    if (isAuthenticated) {
        return <Redirect to='/dashboard' />
    }

    return (
        <section className="landing">
            <div className="dark-overlay">
                <div className="landing-inner">
                    <h2 className="comic landing-heading">Viehmarkt.online</h2>
                    <blockquote className="lead">
                        <td>
                            <tr>Der Viehmarkt 2020 mit</tr>
                            <tr>großer Viehmarktslotterie</tr>
                        </td>
                    </blockquote>
                    <div className="buttons">
                        <Link to="/rides" className="btn btn-margin waves-effect waves-light orange">Besuchen und Lose ziehen</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

Landing.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Landing)
