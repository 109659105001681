import axios from 'axios'
import { setAlert } from './alert'

import {
    GET_PROFILE,
    GET_PROFILES,
    UPSERT_PROFILE,
    PROFILE_ERROR,
    CLEAR_PROFILE,
    GET_GITHUB_REPOS,
    ACCOUNT_DELETED
} from './types'

// Get current user's profile
export const getCurrentProfile = () => async dispatch => {
    try {
        const res = await axios.get('/api/profiles/me')

        dispatch({
            type: GET_PROFILE,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get all profiles
export const getProfiles = () => async dispatch => {

    dispatch({ type: CLEAR_PROFILE })

    try {
        const res = await axios.get('/api/profiles')

        dispatch({
            type: GET_PROFILES,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Get profile by ID
export const getProfileById = userID => async dispatch => {

    try {
        const res = await axios.get(`/api/profiles/user/${userID}`)

        dispatch({
            type: GET_PROFILE,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Upsert current user's profile
export const upsertProfile = (formData, history) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const res = await axios.post('/api/profiles', formData, config)

        dispatch({
            type: UPSERT_PROFILE,
            payload: res.data
        })

        dispatch(setAlert('Profil gespeichert', 'success'))

        history.push('/dashboard')
    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach(error =>
                dispatch(setAlert(error.msg, 'error')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Add ride
export const addRide = (formData, history) => async dispatch => {
    try {
        // const config = {
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // }

        // const res = await axios.put('/api/profiles/rides', formData, config)

        // dispatch({
        //     type: UPSERT_PROFILE,
        //     payload: res.data
        // })

        const res = await axios.post('/api/profiles/rides', formData)




        dispatch(setAlert('Auftritt gespeichert', 'success'))

        history.push('/dashboard')
    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach(error =>
                dispatch(setAlert(error.msg, 'error')));
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Delete ride
export const deleteRide = id => async dispatch => {
    try {
        const res = await axios.delete(`/api/profiles/rides/${id}`)

        dispatch({
            type: UPSERT_PROFILE,
            payload: res.data
        })

        dispatch(setAlert('Auftritt entfernt', 'info'))
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Delete account and profile
export const deleteAccount = () => async dispatch => {

    if (window.confirm('Bist Du sicher? Dein Benutzerkonto wird gelöscht.')) {
        try {
            await axios.delete(`/api/users`)

            dispatch({ type: CLEAR_PROFILE })

            dispatch({ type: ACCOUNT_DELETED })

            dispatch(setAlert('Benutzerkonto gelöscht', 'info'))
        } catch (err) {
            dispatch({
                type: PROFILE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
            })
        }
    }
}

// Get GitHub repos
export const getGitHubRepos = username => async dispatch => {

    try {
        const res = await axios.get(`/api/profiles/github/${username}`)

        dispatch({
            type: GET_GITHUB_REPOS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}