import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { deleteRide } from '../../actions/profile'

const Rides = ({ rides, deleteRide }) => {

    if (rides.length > 0) {
        const theRides = rides.map(ride => (
            <tr key={ride._id}>
                <td>{ride.title}</td>
                <td className="hide-sm">{ride.description}</td>
                <td>
                    <button
                        className="btn btn-margin waves-effect waves-light orange lighten-2"
                        onClick={e => deleteRide(ride._id)}>Löschen</button>
                </td>
            </tr>
        ))
        return (
            <Fragment>
                <h2>Auftritte</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Bezeichnung</th>
                            <th className="hide-sm">Beschreibung</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {theRides}
                    </tbody>
                </table>
            </Fragment>
        )
    } else {
        return (
            <Fragment />
        )
    }
}

Rides.propTypes = {
    rides: PropTypes.array.isRequired,
    deleteRide: PropTypes.func.isRequired,
}

export default connect(null, { deleteRide })(Rides)
