import axios from 'axios'

import {
    SEARCHING,
    SEARCH_RESULT,
    CLEAR_SEARCH,
    SEARCH_ERROR
} from './types'

// Query result profiles
export const queryProfiles = (query) => async dispatch => {

    console.log(query);

    try {
        dispatch({
            type: SEARCHING
        })

        let res

        if (query) {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            res = await axios.post('/api/profiles/search', { query }, config)
        } else {
            res = await axios.get('/api/profiles')
        }

        dispatch({
            type: SEARCH_RESULT,
            payload: {
                query,
                profiles: res.data
            }
        })
    } catch (err) {
        dispatch({
            type: SEARCH_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

// Clear search
export const clearSearch = () => async dispatch => {

    dispatch({
        type: CLEAR_SEARCH
    })
}
