import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Alert from '../layout/Alert'
import Spinner from '../layout/Spinner'
import DashboardActions from './DashboardActions'
import Rides from './Rides'
import { getCurrentProfile } from '../../actions/profile'
import { deleteAccount } from '../../actions/profile'

const Dashboard = ({ getCurrentProfile, deleteAccount, auth: { user }, profile: { profile, loading } }) => {

    useEffect(() => {
        getCurrentProfile()
    }, [getCurrentProfile])

    return (
        loading && profile === null ? <Spinner /> :
            <Fragment>
                <section className="container">
                    <Alert />
                    <h1 className="header orange-text">Mein <span className="comic">Viehmarkt.online</span></h1>
                    <p className="lead">
                        <i className="fas fa-user light-blue-text"></i>{' '}Willkommen{user && (', ' + user.name)}
                    </p>
                    <DashboardActions />
                    <Rides rides={profile ? profile.rides : []} />

                    <div className="divider">

                    </div>
                    <p>
                        <button
                            className="btn btn-margin waves-effect waves-light orange lighten-2"
                            onClick={e => deleteAccount()}>Benutzerkonto löschen</button>
                    </p>
                </section>
            </Fragment>
    )
}

Dashboard.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile
})

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(Dashboard)
