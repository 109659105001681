import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import M from 'materialize-css'

const AWS = require('aws-sdk/dist/aws-sdk-react-native');

const ProfileRides2 = ({ ride: {
    _id,
    title,
    description,
    photo
} }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems);

        const s3 = new AWS.S3({
            accessKeyId: 'AKIAQVO5MXRH7SXUYHGO',
            secretAccessKey: '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',
            region: 'eu-west-1'
        })

        s3.getSignedUrlPromise('getObject', {
            Bucket: 'viehmarkt',
            Key: _id + '.' + photo.substring(photo.lastIndexOf('/') + 1),
            Expires: 60 * 5
        }).then(url => setUrl(url))
    }, [])

    const [url, setUrl] = useState(null)

    return (
        <li>
            <div class="collapsible-header">
                <span>{title}</span>
            </div>
            <div class="collapsible-body">
                <p>{description}</p><br />
                {url ?
                    (
                        <p>
                            <img src={url} className="responsive-img" />
                        </p>
                    ) :
                    (
                        ''
                    )
                }
            </div>
        </li >
    )
}

ProfileRides2.propTypes = {
    ride: PropTypes.object.isRequired
}

export default ProfileRides2
