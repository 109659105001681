import {
    GET_RIDES,
    RIDES_ERROR
} from "../actions/types"

const initialState = {
    rides: [],
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_RIDES:
            return {
                ...state,
                rides: payload,
                loading: false
            }
        case RIDES_ERROR:
            return {
                ...state,
                rides: [],
                loading: false,
                error: payload
            }
        default:
            return state
    }
}
