import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const ProfileCard = ({ profile: {
    company,
    location,
    founded,
    firstappearance,
    statement,
    website,
    social,
    user: { name, avatar } } }) => {
    return (
        <Fragment>
            <div className="col s12 m7">
                <h2 className="header orange-text">{company}</h2>
                <div className="card horizontal">
                    <div className="card-image">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="card-stacked">
                        <div className="card-content">
                            <p>{company}, {location}.</p><br />
                            <p>Gegründet {founded}.</p><br />
                            <p>Erster Auftritt auf dem Viehmarkt {firstappearance}.</p><br />
                            <p>{statement}</p>
                        </div>
                        <div className="card-action">
                            <Fragment>
                                {website && website !== ''
                                    ?
                                    <a href={website} target="_blank" rel="noopener noreferrer"><i className="fas fa-globe fa-2x"></i></a>
                                    :
                                    <a><i className="fas fa-globe fa-2x light"></i></a>
                                }
                            </Fragment>

                            <Fragment>
                                {social.facebook && social.facebook !== ''
                                    ?
                                    <a href={social.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fa-2x"></i></a>
                                    :
                                    <a><i className="fab fa-facebook fa-2x light"></i></a>
                                }
                            </Fragment>

                            <Fragment>
                                {social.twitter && social.twitter !== ''
                                    ?
                                    <a href={social.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter fa-2x"></i></a>
                                    :
                                    <a><i className="fab fa-twitter fa-2x light"></i></a>
                                }
                            </Fragment>

                            <Fragment>
                                {social.instagram && social.instagram !== ''
                                    ?
                                    <a href={social.instagram} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-2x"></i></a>
                                    :
                                    <a><i className="fab fa-instagram fa-2x light"></i></a>
                                }
                            </Fragment>

                            <Fragment>
                                {social.youtube && social.youtube !== ''
                                    ?
                                    <a href={social.youtube} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube fa-2x"></i></a>
                                    :
                                    <a><i className="fab fa-youtube fa-2x light"></i></a>
                                }
                            </Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

ProfileCard.propTypes = {
    profile: PropTypes.object.isRequired
}

export default ProfileCard
