import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <Fragment>
            <footer className="page-footer light-blue lighten-1">
                <div className="footer-copyright">
                    <div className="container">
                        <Link to="/imprint" className="grey-text text-lighten-4">Impressum</Link>
                        <span className="comic grey-text text-lighten-4 right">Auf, auf zum Schützenplatz!</span>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}

export default Footer
