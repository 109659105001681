import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import M from 'materialize-css'

const ProfileItem = ({ profile: { user: { _id, name, avatar }, company, description, statement, rides } }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('.chips');
        M.Chips.init(elems);
    }, [])

    return (
        <Fragment>
            <li className="collection-item avatar">
                <img src={avatar} alt={name} className="circle" />
                <span className="title orange-text">{company}</span>
                <p>{description}</p>
                <div className="skill-chips">
                    {rides.map(ride => (
                        <div className="chip">{ride.title}</div>
                    ))}
                </div>                
                <p>{statement}</p>
                <Link to={`/profiles/${_id}`} className="secondary-content"><i className="material-icons light-blue-text">link</i></Link>
            </li>
        </Fragment>
    )
}

ProfileItem.propTypes = {
    profile: PropTypes.object.isRequired,
}

export default ProfileItem
