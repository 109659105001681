import axios from 'axios'
import { setAlert } from './alert'

import {
    GET_RIDES,
    RIDES_ERROR
} from './types'

// Get all rides
export const getRides = () => async dispatch => {

    try {
        const res = await axios.get('/api/profiles/rides')

        dispatch({
            type: GET_RIDES,
            payload: res.data
        })
    } catch (err) {

        dispatch(setAlert('Keine Auftritte verfügbar', 'error'))

        // dispatch({
        //     type: RIDES_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        // })
    }
}
