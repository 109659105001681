import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Ride from './Ride'
import { getRides } from '../../actions/rides'

const Rides = ({ rides, getRides }) => {

    useEffect(() => {
        getRides()
    }, [getRides])

    return (
        <Fragment>
            <section className="container">
                <h1 className="header orange-text">Auftritte</h1>
                <p className="lead">
                    <i className="fas fa-dice-d20 light-blue-text"></i>{' '}Hier sehen Sie die Auftritte auf dem Viehmarkt in zufälliger Reihenfolge. Ziehen Sie Ihre Lose mit einem Klick auf das <span className="red-text">Pluszeichen</span> rechts unten neben den Fotos der Auftritte. Ein Viehmarktslos kostet 2,50€.
                </p>
                {rides && rides.length > 0 ? (
                    <ul>
                        {rides.sort(() => Math.random() - 0.5).map(ride => (
                            <Ride key={ride._id} ride={ride}></Ride>
                        ))}
                    </ul>
                ) :
                    (
                        ''
                    )
                }
            </section>
        </Fragment>
    )
}

Rides.propTypes = {
    rides: PropTypes.array.isRequired,
    getRides: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    rides: state.rides.rides
})

export default connect(mapStateToProps, { getRides })(Rides)
