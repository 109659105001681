import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import M from 'materialize-css'
import { removeAlert } from '../../actions/alert'

const Alert = ({ alerts, removeAlert }) => {
    if (alerts !== null && alerts.length > 0) {
        alerts.forEach(alert => {
            removeAlert(alert.id)
            M.toast({ html: alert.msg, classes: alert.alertType })
        });
    }

    return (
        <Fragment />
    )
}

Alert.propTypes = {
    alerts: PropTypes.array.isRequired,
    removeAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    alerts: state.alert
})

export default connect(mapStateToProps, { removeAlert })(Alert)
