import React, { Fragment, useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ImageUploader from 'react-images-upload'
import { addRide } from '../../actions/profile'
import Alert from '../layout/Alert'
import M from 'materialize-css'

const AddRide = ({ addRide, history }) => {

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields()
    }, [])

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        photo: null
    })

    const { title, description, photo } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onDrop = (pictures) => {
        setFormData({ ...formData, photo: pictures[0] })
    }

    const onSubmit = e => {
        e.preventDefault()

        const data = new FormData()
        data.append('title', title)
        data.append('description', description)
        data.append('photo', formData.photo)

        addRide(data, history)
    }

    return (
        <Fragment>
            <section className="container">
                <Alert />
                <h1 className="header orange-text">Auftritt</h1>
                <p className="lead">
                    <i className="fas fa-dice-d20 light-blue-text"></i>{' '}Beschreiben Sie hier Ihren Auftritt
                </p>
                <div className="row">

                    <form className="col s12" onSubmit={e => onSubmit(e)}>

                        <div className="row">
                            <div className="input-field col s12">
                                <input
                                    id="title"
                                    type="text"
                                    placeholder="Bezeichnung"
                                    name="title"
                                    value={title}
                                    onChange={e => onChange(e)} />
                                <label className="active" for="title">Bezeichnung des Auftritts</label>
                                <span class="helper-text orange-text">Diese Angabe ist erforderlich.</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <label className="active" for="description">Beschreibung des Auftritts</label>

                                <textarea
                                    id="description"
                                    name="description"
                                    cols="30"
                                    rows="5"
                                    placeholder="Beschreiben Sie hier Ihren Auftritt..."
                                    value={description}
                                    onChange={e => onChange(e)}>
                                </textarea>
                                <span class="helper-text orange-text">Diese Angabe ist erforderlich.</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <ImageUploader
                                    withIcon={true}
                                    buttonText='Bitte wählen Sie ein Foto Ihres Auftritts aus'
                                    onChange={onDrop}
                                    imgExtension={['.jpg', '.png', '.gif']}
                                    maxFileSize={1024 * 1024 * 10}
                                    fileSizeError={'Dieses Foto ist zu groß'}
                                    fileTypeError={'Diese Bildformat wird nicht unterstützt'}
                                    singleImage={true}
                                    label={'Maximale Bildgröße 10MB, unterstützte Bildformate JPG|PNG|GIF|'}
                                    withPreview={true}
                                />
                                <span class="helper-text orange-text">Ein Foto ist erforderlich.</span>
                            </div>
                        </div>

                        <input type="submit" value="Speichern" className="btn btn-margin waves-effect waves-light orange" />

                        <Link to="/dashboard" className="btn btn-margin waves-effect waves-light orange lighten-2" >Zurück</Link>
                    </form>
                </div>
            </section>
        </Fragment>
    )
}

AddRide.propTypes = {
    addRide: PropTypes.func.isRequired,
}

export default connect(null, { addRide })(withRouter(AddRide))
