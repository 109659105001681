
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const initialState = {}

// https://github.com/reduxjs/redux-thunk
const middleware = [thunk]

const store = createStore(
    rootReducer,
    initialState,
    // https://github.com/zalmoxisus/redux-devtools-extension
    composeWithDevTools(applyMiddleware(...middleware)))

export default store
